<template>
    <form @submit.prevent="submit">
        <BaseInput
            type="number"
            v-model.number="internalValue"
            min="0"
            :step="step"
            id="cart_item_number_edit"
            @blur="blur"
            @keypress.enter="submit"
            ref="quantity_edit"
        />
    </form>
</template>

<script>
import BaseInput from '@/core/components/BaseInput'

export default {
    name: 'CartItemNumberInputForm',
    components: {
        BaseInput,
    },
    props: {
        value: {
            type: Number,
            required: true,
        },
        step: {
            type: Number,
            default: 1,
        },
    },
    emits: [
        'change',
        'cancel',
    ],
    data() {
        return {
            internalValue: this.value,
        };
    },
    mounted() {
        this.$refs.quantity_edit.$el.focus();
    },
    methods: {
        submit() {
            this.$emit('change', this.internalValue);
        },
        blur() {
            this.$emit('cancel');
        },
    },
};
</script>

<style scoped>
input {
    width: 100%;
    font-family: 'Courier New', monospace;
    font-size: 1.2em;
    color: #000;
}
</style>
