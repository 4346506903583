<template>
    <li>
        <dl :class="{'is-price-editing': isPriceEditing}">
            <dt>Artikel</dt>
            <dd>{{ cartItem.item.name }}</dd>
            <dt v-if="!isPriceEditing">Antal</dt>
            <dd v-if="!isPriceEditing">
                <span v-if="!isQuantityEditing">
                    {{ cartItem.quantity }}
                    <button @click.prevent="beginEditQuantity">
                        ✏️
                    </button>
                </span>
                <CartItemNumberInputForm
                    v-else
                    :value="cartItem.quantity"
                    @change="quantityUpdated"
                    @cancel="stopEditQuantity"
                />
            </dd>
            <dt>Styckpris</dt>
            <dd class="cell-currency">
                <span v-if="!isPriceEditing">
                    {{ currency(cartItem.item.price) }}
                    <button @click.prevent="beginEditPrice">
                        💰
                    </button>
                </span>
                <CartItemNumberInputForm
                    v-else
                    :value="cartItem.item.price"
                    step="0.01"
                    @change="priceUpdated"
                    @cancel="stopEditPrice"
                />
            </dd>
            <dt>Pris</dt>
            <dd class="cell-currency">
                {{ currency(cartItem.item.price * cartItem.quantity) }}
            </dd>
        </dl>
    </li>
</template>

<script>
import { currency } from '@/core/numberFormat'
import CartItemNumberInputForm from './CartItemNumberInputForm.vue'

export default {
    name: 'CartItem',
    components: {
        CartItemNumberInputForm,
    },
    props: {
        cartItem: {
            type: Object,
            required: true,
        },
    },
    emits: [
        'changeQuantity',
        'changePrice',
    ],
    data() {
        return {
            isQuantityEditing: false,
            isPriceEditing: false,
        };
    },
    methods: {
        currency,
        beginEditQuantity() {
            this.isQuantityEditing = true;
        },
        stopEditQuantity() {
            this.isQuantityEditing = false;
        },
        quantityUpdated(newQuantity) {
            const cartItem = Object.assign({}, this.cartItem);
            cartItem.quantity = newQuantity;

            this.$emit('changeQuantity', cartItem);

            this.stopEditQuantity();
        },
        beginEditPrice() {
            this.isPriceEditing = true;
        },
        stopEditPrice() {
            this.isPriceEditing = false;
        },
        priceUpdated(newPrice) {
            const cartItem = Object.assign({}, this.cartItem);
            cartItem.item.price = newPrice;

            this.$emit('changePrice', cartItem);

            this.stopEditPrice();
        },
    },
};
</script>

<style scoped>
li {
    padding: 0.5em;
    background: #fff;
    border-bottom: 3px dotted #4079ba;
    font-family: 'Courier New', monospace;
    font-size: 1.5em;
    color: #000;
}

@media screen and (max-width: 1200px) {
    li {
        font-size: 1em;
    }
}

li > dl {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    align-items: center;
    gap: 5px;
}

li > dl.is-price-editing {
    grid-template-columns: 3fr 2fr 1fr;
}

li > dl > dt {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
}

li > dl > dd {
    margin: 0;
}
</style>
