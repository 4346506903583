<template>
    <input
        :type="type"
        :id="id"
        :value="modelValue"
        @input="inputUpdated"
    />
</template>

<script>
export default {
    name: 'BaseInput',
    props: {
        type: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        modelValue: {
            type: [String, Number],
            required: true,
        },
    },
    methods: {
        inputUpdated(e) {
            this.$emit('update:modelValue', e.target.value);
        },
    },
};
</script>
