<template>
    <div id="barcode_scanner"></div>
</template>

<script>
import { Html5QrcodeScanner } from 'html5-qrcode';

export default {
    name: 'BarcodeScanner',
    props: {
        enabled: Boolean,
    },
    data() {
        return {
            scanner: null,
        };
    },
    emits: [
        'read',
    ],
    watch: {
        enabled() {
            if (!this.scanner) {
                return;
            }

            this.scanner.resume();
        },
    },
    mounted() {
        this.scanner = new Html5QrcodeScanner('barcode_scanner', {
            fps: 10,
            qrbox: {
                width: 250,
                height: 100,
            },
            formatsToSupport: [
                9, // EAN_13,
                10, // EAN_8
                14, // UPC_A
                15, // UPC_E
            ],
            supportedScanTypes: [
                0,
            ],
            showTorchButtonIfSupported: true,
            showZoomSliderIfSupported: true,
        });

        this.scanner.render(this.scanSuccess.bind(this));
    },
    methods: {
        scanSuccess(decodedText, decodedResult) {
            this.scanner.pause(true);
            this.$emit('read', decodedText, decodedResult);
        },
    },
};
</script>
