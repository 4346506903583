<template>
    <div class="form-buttons">
        <slot>
            <button type="submit">Spara</button>
        </slot>
        <slot name="after_save" />
    </div>
</template>

<script>
export default {
    name: 'BaseFormButtons',
};
</script>

<style>
.form-buttons {
    display: flex;
    flex-flow: row;
    margin: 1em 0;
}

.form-buttons > button {
    -webkit-appearance: none;
    appearance: none;
    padding: 1em;
    background: linear-gradient(#fff, #ccc);
    border: 1px solid #ccc;
}

.form-buttons > button:active {
    background: linear-gradient(#ccc, #fff);
}
</style>
