<template>
    <div class="form-group">
        <label :for="id">{{ label }}</label>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'BaseFormComponent',
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
};
</script>

<style>
.form-group {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 0.5em;
}

.form-group > label {
    padding: 0.3em 0;
}

.form-group > input,
.form-group > textarea,
.form-group > select {
    padding: 0.3em;
    width: 100%;
    background: #fff;
    border: 1px solid #000;
}
</style>
