<template>
    <div>
        <AddItemForm
            v-if="isAddingItem"
            :ean="ean"
            @created="newItemCreated"
            @cancel="cancelNewItem"
        />

        <form v-show="!isAddingItem" @submit.prevent="submit">
            <BarcodeScanner :enabled="!isAddingItem" @read="barcodeRead" />

            <BaseFormComponent id="cart_item_add_ean" label="Lägg till vara">
                <BaseInput
                    type="text"
                    v-model="ean"
                    id="cart_item_add_ean"
                    @keypress.enter.prevent="submit"
                    ref="ean"
                    autocomplete="off"
                />
            </BaseFormComponent>
        </form>
    </div>
</template>

<script>
import { getErrors } from '@/core/http'
import BaseFormComponent from '@/core/components/BaseFormComponent'
import BaseInput from '@/core/components/BaseInput'
import cartsHttp from '@/modules/home/cartsHttp'
import itemsHttp from '@/modules/item/itemsHttp'
import AddItemForm from '@/modules/item/components/AddItemForm'
import BarcodeScanner from '@/modules/cart/components/BarcodeScanner.vue';

export default {
    name: 'AddCartItemForm',
    components: {
        AddItemForm,
        BarcodeScanner,
        BaseFormComponent,
        BaseInput,
    },
    props: {
        cart: {
            type: Object,
            required: true,
        },
    },
    emits: [
        'change',
    ],
    data() {
        return {
            ean: '',
            isAddingItem: false,
        };
    },
    mounted() {
        this.focusEan();
    },
    methods: {
        focusEan() {
            this.$refs.ean.$el.focus();
        },
        barcodeRead(ean) {
            if (this.isAddingItem) {
                return;
            }

            this.ean = ean;
            this.submit();
        },
        async submit() {
            const cartItem = this.findItemInCart(this.ean);
            if (cartItem) {
                await this.updateCartItem(cartItem);

                return;
            }

            const item = await this.fetchItem(this.ean);

            if (item === null) {
                this.isAddingItem = true;

                return;
            }

            await this.createCartItem(item.id);
        },
        async fetchItem(ean) {
            try {
                return await itemsHttp.get(ean);

            } catch (err) {
                if (err.status === 404) {
                    return null;
                }

                this.handleHttpErrors(err);
            }
        },
        async createCartItem(itemId) {
            try {
                const cart = await cartsHttp.putItem(this.cart.id, itemId, {
                    quantity: 1,
                });

                this.$emit('change', cart);
                this.ean = '';

            } catch (err) {
                this.handleHttpErrors(err);
            }
        },
        async updateCartItem(cartItem) {
            try {
                const cart = await cartsHttp.putItem(this.cart.id, cartItem.item.id, {
                    quantity: cartItem.quantity + 1,
                });

                this.$emit('change', cart);
                this.ean = '';

            } catch (err) {
                this.handleHttpErrors(err);
            }
        },
        async newItemCreated(item) {
            this.isAddingItem = false;
            await this.createCartItem(item.id);
        },
        findItemInCart(ean) {
            return this.cart.items.find(cartItem => cartItem.item.id === ean);
        },
        cancelNewItem() {
            this.isAddingItem = false;
            this.ean = '';

            this.$nextTick(() => {
                window.scrollTo({
                    top: 0,
                });
            });
        },
        handleHttpErrors(err) {
            const errors = getErrors(err);

            errors.forEach(text => {
                this.$flashMessage.show({
                    type: 'error',
                    text,
                });
            });
        },
    },
};
</script>
