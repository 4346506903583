/**
 * @param {Number} number
 * @return {string}
 */
export const currency = number => {
    if (typeof number !== 'number' || isNaN(number)) {
        return '-';
    }

    return number.toLocaleString('sv-SE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};
