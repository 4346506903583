<template>
    <form @submit.prevent="submit">
        <div class="form-group">
            <span>EAN:</span>
            <span>{{ ean }}</span>
        </div>

        <BaseFormComponent id="item_add_name" label="Namn på vara">
            <BaseInput
                type="text"
                v-model="name"
                id="item_add_name"
                ref="name"
                autocomplete="off"
            />
        </BaseFormComponent>

        <BaseFormComponent id="item_add_price" label="Pris">
            <BaseInput
                type="number"
                v-model="price"
                id="item_add_price"
                min="0.01"
                max="9999999999999.99"
                step="0.01"
                ref="price"
                autocomplete="off"
            />
        </BaseFormComponent>

        <BaseFormButtons>
            <template v-slot:after_save>
                <button @click.prevent="cancel">Avbryt</button>
            </template>
        </BaseFormButtons>
    </form>
</template>

<script>
import { getErrors } from '@/core/http'
import BaseFormComponent from '@/core/components/BaseFormComponent'
import BaseInput from '@/core/components/BaseInput'
import BaseFormButtons from '@/core/components/BaseFormButtons'
import itemsHttp from '@/modules/item/itemsHttp'

export default {
    name: 'AddItemForm',
    components: {
        BaseFormComponent,
        BaseInput,
        BaseFormButtons,
    },
    props: {
        ean: {
            type: String,
            required: true,
        },
    },
    emits: [
        'created',
        'cancel',
    ],
    data() {
        return {
            name: '',
            price: '',
        };
    },
    mounted() {
        this.$refs.name.$el.focus();
    },
    methods: {
        async submit() {
            try {
                const item = await itemsHttp.post({
                    ean: this.ean,
                    name: this.name,
                    price: this.price,
                });

                this.$emit('created', item);

            } catch (err) {
                this.handleHttpErrors(err);
            }
        },
        handleHttpErrors(err) {
            const errors = getErrors(err);

            errors.forEach(text => {
                this.$flashMessage.show({
                    type: 'error',
                    text,
                });
            });
        },
        cancel() {
            this.$emit('cancel');
        },
    },
};
</script>
