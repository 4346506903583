<template>
    <main v-if="cart">
        <h1>{{ cart.name }}</h1>

        <AddCartItemForm
            :cart="cart"
            @change="cartUpdated"
        />

        <ul id="cart_items_list">
            <CartItem
                v-for="cartItem in cart.items"
                :key="cartItem.item.id"
                :cart-item="cartItem"
                @changeQuantity="cartItemQuantityChanged"
                @changePrice="cartItemPriceChanged"
            />
        </ul>

        <section id="cart_total_sum">
            <span>
                Totalt
            </span>
            <span class="cell-currency">
                {{ currency(totalSum) }}
            </span>
        </section>
    </main>
</template>

<script>
import { getErrors } from '@/core/http'
import { currency } from '@/core/numberFormat'
import cartsHttp from '@/modules/home/cartsHttp'
import AddCartItemForm from '@/modules/cart/components/AddCartItemForm'
import CartItem from '@/modules/cart/components/CartItem'
import itemsHttp from '@/modules/item/itemsHttp';

export default {
    name: 'Cart',
    components: {
        AddCartItemForm,
        CartItem
    },
    data() {
        return {
            cart: null,
        };
    },
    computed: {
        totalSum() {
            return this.cart.items.reduce(
                (carry, cartItem) => carry + (cartItem.quantity * cartItem.item.price),
                0,
            );
        },
    },
    created() {
        this.fetchCart(this.$route.params.id)
            .then(cart => {
                this.cart = cart;
            })
            .catch(this.handleHttpErrors)
        ;
    },
    methods: {
        currency,
        handleHttpErrors(err) {
            const errors = getErrors(err);

            errors.forEach(text => {
                this.$flashMessage.show({
                    type: 'error',
                    text,
                });
            });
        },
        async fetchCart(id) {
            return await cartsHttp.get(id);
        },
        cartUpdated(newCart) {
            this.cart = newCart;
        },
        cartItemQuantityChanged(newCartItem) {
            if (newCartItem.quantity > 0) {
                this.updateCartItemQuantity(newCartItem);
            } else {
                this.deleteCartItem(newCartItem.item.id);
            }
        },
        cartItemPriceChanged(newCartItem) {
            this.updateCartItemPrice(newCartItem.item);
        },
        updateCartItemQuantity(newCartItem) {
            cartsHttp.putItem(this.cart.id, newCartItem.item.id, {
                quantity: newCartItem.quantity,
            })
                .then(this.cartUpdated)
                .catch(this.handleHttpErrors)
            ;
        },
        deleteCartItem(itemId) {
            cartsHttp.deleteItem(this.cart.id, itemId)
                .then(this.cartUpdated)
                .catch(this.handleHttpErrors)
            ;
        },
        updateCartItemPrice(newItem) {
            itemsHttp.put(newItem.id, {
                name: newItem.name,
                price: newItem.price,
            })
                // .then(this.cartUpdated)
                .catch(this.handleHttpErrors)
            ;
        },
    },
};
</script>

<style scoped>
#cart_items_list {
    margin: 0;
    padding: 0;
    list-style: none;
}

#cart_total_sum {
    display: grid;
    grid-template-columns: 4fr 1fr;
    padding: 0.5em;
    background: #fff;
    font-family: 'Courier New', monospace;
    font-weight: 700;
    font-size: 1.5em;
    color: #000;
}
</style>
